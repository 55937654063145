import { FormControl, Grid, InputAdornment, InputLabel, MenuItem, Select, TextField } from "@mui/material"
import { MoneyFormat } from "./money"
import React from "react"
import { ExpenseMap } from "./expenses/Expense"


function MonthlyAnnualAmmount(props) {

    var [annual, setAnnual] = React.useState(props.monthly ? false : true)
    var [modified, setModified] = React.useState(false)
    var [value, setValue] = React.useState()

        return (
        <Grid container xs={12}>
            <Grid item xs={12}>
                <InputLabel htmlFor="standard-adornment-amount">{props.label}</InputLabel>
            </Grid>
            <Grid item component={FormControl} variant='outlined' container xs={12}>
                <Grid item xs={12}>
                    <TextField inputProps={{step: '0.1'}} InputProps={{
                        startAdornment: <InputAdornment position="start">£</InputAdornment>,
                        sx: {paddingRight: 0},  
                        endAdornment: <InputAdornment position="end">
                            <Select
                                sx={{ backgroundColor: '#f3f3f3', width: '120px' }}
                                value={annual}
                                onChange={(e) => {
                                    setValue(parseFloat(value ? (annual ? value / 12 : value * 12) : (annual ? props.value / 12 : props.value * 12)).toFixed(2))
                                    setAnnual(!annual)
                                }}
                                
                            >
                                <MenuItem value={false}>Monthly</MenuItem>
                                <MenuItem value={true}>Yearly</MenuItem>
                            </Select>
                        </InputAdornment>
                    }} type='number' disabled={props.disabled} fullWidth value={modified ? value : (parseFloat(annual ? props.value : props.value / 12)).toFixed(2)} onChange={(e) => {
                        setModified(true)
                        setValue(parseFloat(e.target.value))
                        props.onChange({value: e.target.value, annual: annual})
                    }} />
                </Grid>
            </Grid>
        </Grid>
    )
}

export default MonthlyAnnualAmmount