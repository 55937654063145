import { Card, CardContent, Container, Paper, Step, StepLabel, Toolbar, Typography, Grid, TextField, Button, TableContainer, TableBody, TableRow, Table, TableHead, TableCell, CardMedia, CardActions, Select, MenuItem, InputLabel, Input, Slider, CardHeader, InputAdornment, Breadcrumbs, Accordion, AccordionSummary, AccordionDetails, styled, containerClasses, Modal, Dialog, DialogTitle, DialogContent, DialogContentText, IconButton, Alert } from '@mui/material';
import React, { useState } from 'react';
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'

import { Link, RouterProvider, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';

import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';

import InfoIcon from '@mui/icons-material/Info';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


import MuiAccordionSummary, {
    AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import Info from '@mui/icons-material/Info';
import { calculateRepayments, calculateSavings, createSavings, deleteExpense, finances, getCredit, getExpense, getSavings, updateCredit, updateExpense, updateSavings } from '../store/store';
import { Line } from 'react-chartjs-2';
import { MoneyFormat, MoneyTextFormat, PercentTextFormat, PercentageFormat } from '../money';
import { ExpenseMap } from '../expenses/Expense';
import DeleteDialog from '../deletedialog';
import AmountPercent from '../AmountPercent';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { useFinances, useFinancesDispatch } from '../store/FinancesContext';
import { DataGrid } from '@mui/x-data-grid';
import Period from '../periods/Period';

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

function Savings() {
    var navigate = useNavigate()

    var finances = useFinances()
    var financesDispatch = useFinancesDispatch()

    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('sm'));

    var [selectedPeriod, setSelectedPeriod] = useState(null)

    var { savingsId } = useParams()
    var year = useSearchParams()[0].get('year')
    var deposit = useSearchParams()[0].get('deposit')

    var expense = finances.savings.find((f) => f.id == savingsId)
    console.debug("Retrieved savings", expense)

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'bottom',
                display: false
            },
        },
    };

    var [savings, setSavings] = useState(expense ? expense : {
        name: '',
        periods: [
            {
                start: null,
                end: null,
                amount: 0,
                monthly: 0,
                interest: 0
            }
        ]

    })

    var savingsCalc = calculateSavings(finances, savings, false)

    var minYear = savingsCalc[0]?.year
    var maxYear = savingsCalc[0]?.year + 70

    const labels = [];
    for (var i = minYear; i < maxYear + 1; i++) {
        labels.push(i)
    }

    console.debug("Expense state=", expense)

    savings.periods.sort((a, b) => {
        if (a.start == null || b.start == null) {
            return 1
        }

        return a.start - b.start
    })

    if (selectedPeriod == null) {
        setSelectedPeriod(savings.periods.length - 1)
    }
    var selectedDeposit = deposit ? savings.deposits.find((f) => f.start == parseInt(deposit)) : savings.deposits ? savings.deposits[savings.deposits.length - 1] : setSavings({
        ...savings,
        deposits: [{
            start: null,
            end: null,
            from: null,
            amount: 0
        }]
    })


    const [showDelete, setShowDelete] = React.useState()

    return (
        <>
            {showDelete &&
                <DeleteDialog delete={showDelete.delete} name={showDelete.name} handleClose={() => setShowDelete(null)} />
            }
            <Grid container spacing={2}>
                <Grid container item xs={12}>
                    <Paper style={{ width: '100%', padding: '20px' }}>
                        <Grid container item xs={12} spacing={2}>
                            <Grid item xs={12}>
                                <InputLabel htmlFor="standard-adornment-amount">Type</InputLabel>
                                <Select
                                    value={savings.type}
                                    onChange={(e) => {
                                        setSavings({
                                            ...savings,
                                            type: e.target.value
                                        })
                                    }}
                                    fullWidth
                                >
                                    <MenuItem value={'general'}>General</MenuItem>
                                    <MenuItem value={'isa'}>Individual Savings Account (ISA)</MenuItem>
                                    <MenuItem value={'emergencyfund'}>Emergency Fund</MenuItem>
                                    <MenuItem value={'privatepension'}>Private Pension</MenuItem>
                                    <MenuItem value={'investment'}>Investment</MenuItem>
                                </Select>
                            </Grid>
                            <Grid item xs={12}>
                                <InputLabel htmlFor="standard-adornment-amount">Name</InputLabel>
                                <TextField fullWidth id="outlined-basic" defaultValue={savings.name} onChange={(e) => { setSavings({ ...savings, name: e.target.value }) }} />
                            </Grid>
                        </Grid>

                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant='h5'>Forecast</Typography>
                </Grid>
                <Grid container item xs={12}>
                    <Paper style={{ width: '100%', padding: '20px' }}>

                        <Line data={{
                            datasets: (() => {
                                return [
                                    {
                                        data: (() => {
                                            var res = calculateSavings(finances, savings, false).filter((f) => f.year < maxYear)
                                            return res.map((p) => {

                                                return {
                                                    x: p.year,
                                                    y: p.total
                                                }
                                            })
                                        })(),
                                        borderColor: `#1976d2`,
                                        pointStyle: false
                                    }
                                ]
                            })(),
                            labels
                        }} options={options}></Line>

                    </Paper>
                </Grid>
                <Period monthly={false} periods={savings.periods} selectedPeriod={savings.periods[selectedPeriod]} update={() => {
                    setSavings({
                        ...savings
                    })
                }} editLink={(selYear) => {
                    var p = selYear && savings.periods.findIndex((f) => selYear.format('YYYY-MM-DD') == f.start)
                    setSelectedPeriod(p != -1 ? p : savings.periods.length - 1)
                }} getAmount={(period) => {
                    return period.monthly ? period.monthly : period.percent ? { percent: period.percent } : 0
                }} addPeriod={(periods) => {
                    periods.push({
                        start: null,
                        end: null,
                        amount: 0,
                        monthly: 0,
                        interest: 0
                    })
                }}>
                    <Grid container item spacing={2} xs={12}>
                        <Grid item xs={6}>
                            <InputLabel htmlFor="standard-adornment-amount">Amount</InputLabel>
                            <TextField InputProps={{
                                inputComponent: MoneyFormat,
                                startAdornment: <InputAdornment position="start">£</InputAdornment>,

                            }} fullWidth id="outlined-basic" key={`amount-${savings.periods[selectedPeriod]?.start}`} placeholder={Math.round(savingsCalc.find((f) => f.year == dayjs(savings.periods[selectedPeriod]?.start, 'YYYY-MM-DD').year() - 1)?.total)} value={savings.periods[selectedPeriod]?.amount} onChange={(e) => {
                                setSavings({
                                    ...savings,
                                    periods: savings.periods.map((m) => {
                                        if (m.start == savings.periods[selectedPeriod]?.start) {
                                            m.amount = parseFloat(e.target.value)
                                        }

                                        return m
                                    })
                                })
                            }} variant="outlined" />
                        </Grid>
                        <Grid item xs={6}>
                            <InputLabel htmlFor="standard-adornment-amount">Interest</InputLabel>
                            <TextField InputProps={{
                                inputComponent: PercentageFormat,
                            }} fullWidth id="outlined-basic" key={`amount-${savings.periods[selectedPeriod]?.start}`} defaultValue={savings.periods[selectedPeriod]?.interest * 100} onChange={(e) => {
                                setSavings({
                                    ...savings,
                                    periods: savings.periods.map((m) => {
                                        if (m.start == savings.periods[selectedPeriod]?.start) {
                                            m.interest = parseFloat(e.target.value) / 100
                                        }

                                        return m
                                    })
                                })
                            }} variant="outlined" />
                        </Grid>

                    </Grid>
                    <Grid item xs={12} >
                        <AmountPercent percent={savings.periods[selectedPeriod]?.percent} amount={savings.periods[selectedPeriod]?.monthly} key={savings.periods[selectedPeriod]?.start} label='Monthly Contribution' setPercent={(e) => {
                            setSavings({
                                ...savings,
                                periods: savings.periods.map((m) => {
                                    if (m.start == savings.periods[selectedPeriod]?.start) {
                                        m.monthly = null
                                        m.percent = parseFloat(e)
                                    }

                                    return m
                                })
                            })
                        }} setAmount={(e) => {
                            setSavings({
                                ...savings,
                                periods: savings.periods.map((m) => {
                                    if (m.start == savings.periods[selectedPeriod]?.start) {
                                        m.percent = null
                                        m.monthly = parseFloat(e)
                                    }

                                    return m
                                })
                            })
                        }} />

                    </Grid>
                    <Grid item container xs={12}>
                        <InputLabel htmlFor="standard-adornment-amount">Comments</InputLabel>
                        <TextField
                            id="filled-multiline-flexible"
                            key={`amount-${savings.periods[selectedPeriod]?.start}`}
                            defaultValue={savings.periods[selectedPeriod]?.comments}
                            onChange={(e) => {
                                setSavings({
                                    ...savings,
                                    periods: savings.periods.map((m) => {
                                        if (m.start == savings.periods[selectedPeriod]?.start) {
                                            m.comments = e.target.value
                                        }

                                        return m
                                    })
                                })
                            }}
                            fullWidth
                            multiline
                            maxRows={4}
                        />
                    </Grid>


                </Period >

                <Grid item xs={12}>
                    <Accordion disableGutters={true} elevation={0} sx={{ backgroundColor: 'transparent' }}>
                        <AccordionSummary sx={{ padding: 0 }} expandIcon={<ExpandMoreIcon />}>
                            <Typography variant='h5'>Deposits</Typography>
                        </AccordionSummary>
                        <AccordionDetails sx={{ padding: 0 }}>
                            <Grid item container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography variant='body1'>Sometimes we receive significant sums of money, such as remortgages, inheritance and retirement lump sums. Deposits allow you to add a sums of money to savings accounts.</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant='h6'>Incoming</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <TableContainer component={Paper}>
                                        <Table aria-label="simple table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Start</TableCell>
                                                    <TableCell>End</TableCell>
                                                    <TableCell>Deposit</TableCell>
                                                    <TableCell></TableCell>
                                                    <TableCell></TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {
                                                    savings.deposits && savings.deposits.map((c) => {
                                                        return <TableRow selected={c.start == selectedDeposit?.start}>
                                                            <TableCell>{c.start}</TableCell>
                                                            <TableCell>{c.end}</TableCell>
                                                            <TableCell><MoneyTextFormat value={c.amount} /> </TableCell>
                                                            <TableCell align='right'><IconButton component={Link} replace={true} to={`/savings/${savings.id}?deposit=${c.start}`}><EditIcon /></IconButton></TableCell>
                                                            <TableCell align='right'><IconButton onClick={() => {
                                                                setSavings({
                                                                    ...expense,
                                                                    deposits: savings.deposits.filter((f) => f.start != c.start)
                                                                })
                                                            }}><DeleteIcon /></IconButton></TableCell>
                                                        </TableRow>
                                                    })
                                                }
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                                <Grid container item justifyContent='flex-end' xs={12}>
                                    <Button onClick={() => {
                                        var deposit = {
                                            start: null,
                                            end: null,
                                            amount: 0,
                                            from: 'net'
                                        }

                                        if (!savings.deposits) savings.deposits = []

                                        savings.deposits.push(deposit)

                                        setSavings({ ...savings })

                                    }
                                    } variant='contained'>Add</Button>
                                </Grid>
                                <Grid container spacing={2} item xs={12}>
                                    <Grid item xs={12}>
                                        <Paper style={{ padding: '20px' }}>
                                            <Grid container item spacing={2} xs={12}>
                                                <Grid container item spacing={2} xs={12}>
                                                    <Grid item xs={6}>
                                                        <InputLabel htmlFor="standard-deposit-start">Start</InputLabel>
                                                        <DatePicker key={`startd-${selectedDeposit?.start}`}
                                                            value={selectedDeposit?.start ? dayjs().year(selectedDeposit?.start) : null}
                                                            views={['year']}
                                                            format="YYYY"
                                                            slotProps={{ textField: { fullWidth: true }, actionBar: { actions: ['clear'] } }}
                                                            onChange={(e) => {
                                                                selectedDeposit.start = e ? parseInt(e.format('YYYY')) : null
                                                                setSavings({ ...savings })
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <InputLabel htmlFor="standard-deposit-end">End</InputLabel>
                                                        <DatePicker key={`endd-${selectedDeposit?.end}`}
                                                            value={selectedDeposit?.end ? dayjs().year(selectedDeposit?.end) : null}
                                                            views={['year']}
                                                            format="YYYY"
                                                            slotProps={{ textField: { fullWidth: true }, actionBar: { actions: ['clear'] } }}
                                                            onChange={(e) => {
                                                                selectedDeposit.end = e ? parseInt(e.format('YYYY')) : null
                                                                setSavings({ ...savings })
                                                            }}
                                                        />

                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <InputLabel htmlFor="standard-deposit-from">From</InputLabel>
                                                    <Select
                                                        id="type"
                                                        value={selectedDeposit?.from}
                                                        onChange={(e) => {
                                                            selectedDeposit.from = e.target.value
                                                            setSavings({ ...savings })

                                                        }}
                                                        fullWidth
                                                    >
                                                        <MenuItem value='net'>Net</MenuItem>
                                                        {
                                                            finances.savings.map((m) => {
                                                                return <MenuItem value={m.id}>{m.name}</MenuItem>
                                                            })
                                                        }
                                                        <MenuItem value='other'>Other</MenuItem>
                                                    </Select>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <InputLabel htmlFor="standard-adornment-amount">Amount</InputLabel>
                                                    <TextField InputProps={{
                                                        inputComponent: MoneyFormat,
                                                        startAdornment: <InputAdornment position="start">£</InputAdornment>,

                                                    }} fullWidth id="outlined-basic" key={`amountd-${selectedDeposit?.start}`} value={selectedDeposit?.amount} onChange={(e) => {
                                                        selectedDeposit.amount = parseFloat(e.target.value)
                                                        setSavings({
                                                            ...savings
                                                        })
                                                    }} variant="standard" />
                                                </Grid>
                                            </Grid>



                                        </Paper>

                                    </Grid>

                                </Grid >
                                <Grid item xs={12}>
                                    <Typography variant='h6'>Outgoing</Typography>
                                </Grid>
                                <Grid container spacing={2} item xs={12}>
                                    <Grid item xs={12}>

                                        <TableContainer component={Paper}>
                                            <Table aria-label="simple table">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>From</TableCell>
                                                        <TableCell>Year</TableCell>
                                                        <TableCell>Amount</TableCell>
                                                        <TableCell></TableCell>
                                                        <TableCell></TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {
                                                        finances.savings.flatMap((f) => {
                                                            var r = f.deposits?.filter((p) => p.from == savings.id).flatMap((m) => {
                                                                return {
                                                                    s: f,
                                                                    p: m
                                                                }
                                                            })
                                                            return r ? r : []
                                                        }).sort((a, b) => a.p.start - b.p.start).map((d) => {
                                                            return <TableRow>
                                                                <TableCell>{finances.savings.find((s) => s.id == d.s.id).name}</TableCell>
                                                                <TableCell>{d.p.start}</TableCell>
                                                                <TableCell>{d.p.amount}</TableCell>
                                                                <TableCell align='right'><Link to={`/savings/${d.s.id}`}><EditIcon /></Link></TableCell>
                                                                <TableCell align='right'><IconButton onClick={() => {
                                                                    setShowDelete({
                                                                        delete: () => {

                                                                            // TODO delete deposit
                                                                            deleteExpense(d.m.id)
                                                                            setShowDelete(null)
                                                                            navigate(`/savings/${savingsId}`)
                                                                        },
                                                                        name: finances.savings.find((s) => s.id == d.s.id).name
                                                                    })
                                                                }}><DeleteIcon /></IconButton></TableCell>
                                                            </TableRow>
                                                        })
                                                    }
                                                </TableBody>
                                            </Table>
                                        </TableContainer>

                                    </Grid>
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                </Grid>
                <Grid container item spacing={2} justifyContent='flex-end'>
                    <Grid item>
                        <Button variant='outlined' onClick={(a) => {
                            setShowDelete({
                                delete: () => {
                                    financesDispatch({
                                        type: 'delete',
                                        financeType: 'savings',
                                        id: savings.id
                                    })
                                    setShowDelete(null)
                                    navigate(-1)
                                },
                                name: savings.name
                            })
                        }}>Delete</Button>
                    </Grid>
                    <Grid item>
                        {savings.id ? <Button onClick={() => { financesDispatch({ type: 'update', financeType: 'savings', item: savings }); navigate(-1) }} variant='contained'>Update Savings</Button> : <Button onClick={() => { financesDispatch({ type: 'add', financeType: 'savings', item: { ...savings } }); navigate(-1) }} variant='contained'>Add Savings</Button>}
                    </Grid>
                </Grid>


                <Grid item xs={12}>
                    <Typography variant='h5'>Expenses</Typography>
                </Grid>
                <Grid container spacing={2} item xs={12}>
                    <Grid item xs={12}>
                        <Paper>
                            <DataGrid autoHeight disableColumnResize disableColumnMenu disableRowSelectionOnClick columns={
                                [
                                    {
                                        field: 'id'
                                    },
                                    {
                                        field: 'expense',
                                        flex: 0.4,
                                        headerName: 'Expense',
                                    },
                                    {
                                        field: 'year',
                                        flex: 0.2,
                                        headerName: 'Year',
                                    },
                                    {
                                        field: 'amount',
                                        flex: 0.3,
                                        headerName: 'Amount',
                                        renderCell: (a) => {
                                            return <MoneyTextFormat value={a.row.amount} />
                                        }
                                    },
                                    {
                                        field: 'edit',
                                        align: 'right',
                                        flex: 0.1,
                                        renderCell: (a) => {
                                            return <IconButton component={Link} to={`/expense/${a.row.expenseId}`}><EditIcon /></IconButton>
                                        },
                                        headerName: ''
                                    }
                                ]
                            } initialState={{
                                columns: {
                                    columnVisibilityModel: {
                                        id: false,
                                        year: matches
                                    }
                                },
                                pagination: {
                                    paginationModel: {
                                        pageSize: 5
                                    }
                                },
                                sorting: {
                                    sortModel: [{ field: 'year', sort: 'asc' }]
                                }
                            }} rows={finances.expenses.flatMap((f) => {
                                return f.periods.filter((p) => p.pay == savings.id).flatMap((m) => {
                                    return {
                                        m: f,
                                        p: m
                                    }
                                })
                            }).sort((a, b) => a.p.start - b.p.start).map((d) => {
                                return {
                                    id: `${d.m.id}+${d.p.start}`,
                                    expenseId: d.m.id,
                                    expense: d.m.type == 'other' ? d.m.customname : ExpenseMap[d.m.type],
                                    year: dayjs(d.p.start, 'YYYY-MM-DD').year(),
                                    amount: d.p.amount
                                }
                            })

                            }
                            />
                        </Paper>
                    </Grid>

                </Grid>


            </Grid >
        </>


    );
}

export default Savings;
