import { getTodayDate } from "@mui/x-date-pickers/internals"
import dayjs from "dayjs"
import { useContext } from "react"
import { useFinances } from "./FinancesContext"

export var settings = {
    inflation: 1.02,
    studentloan: [
        {
            type: 'plan1',
            start: 2020,
            end: null,
            threshold: 22015,
            percent: 0.09,
            interest: 0.0625
        },
        {
            type: 'plan2',
            start: 2020,
            end: null,
            threshold: 27295,
            percent: 0.09,
            interest: 0.076
        },
        {
            type: 'plan4',
            start: 2020,
            end: null,
            threshold: 27660,
            percent: 0.09,
            interest: 0.0625
        },
        {
            type: 'plan5',
            start: 2020,
            end: null,
            threshold: 25000,
            percent: 0.09,
            interest: 0.076
        },
        {
            type: 'postgraduate',
            start: 2020,
            end: null,
            threshold: 21000,
            percent: 0.06,
            interest: 0.076
        }
    ],
    tax: {
        inflation: 1.01,
        personalAllowance: [
            {
                start: 2020,
                end: null,
                amount: 12100
            }
        ],
        upperThreshold: [
            {
                start: 2020,
                end: null,
                amount: 48000
            }
        ],
        basicTaxRate: [
            {
                start: 2020,
                end: null,
                percentage: 0.2
            }
        ],
        upperTaxRate: [
            {
                start: 2020,
                end: null,
                percentage: 0.4
            }
        ],
        basicNIRate: [
            {
                start: 2020,
                end: 2024,
                percentage: 0.1
            },
            {
                start: 2025,
                end: null,
                percentage: 0.08
            }
        ],
        upperNIRate: [
            {
                start: 2020,
                end: null,
                percentage: 0.02
            }
        ]
    },
    pension: {
        statePensionAge: 65,
        maxStatePensionYears: 35
    }


}


var fileId = null
var subscriptId = null

export const { getRawIncome, calculateInflatedValue, getIncomes, getSavingsIncludingPension, getExpenseAnnualTotal, storeSubscriptionId, loadSubscriptionId, saveJsonGoogleDrive, loadScenario, loadScenarios, createScenario, reallocateExpensesToNetForId, loadDemo, deleteJson, createCredit, createSavings, createIncome, createLoan, createExpense, deleteCredit, deleteSavings, deleteIncome, deleteLoan, deleteExpense, setFinances, saveJson, getAnnualIncome, updateIncome, getIncome, updateSavings, getSavings, getSavingsAnnualTotal, calculateSavings, updateExpense, calculateRepayments, updateCredit, updateLoan, getLoan, getCredit, getExpense, getNetAnnualIncome, getCreditAnnualTotal, getLoanAnnualTotal, getNetExpenseAnnualTotal, getLoanMonthlyPayment, getIncomeAfterExpenses } = {
    storeSubscriptionId: (subscriptionId) => {
        // TODO what if previously subscribed?
        saveJsonGoogleDrive('subscription', {
            subscriptionId: subscriptionId
        })
    },
    loadSubscriptionId: () => {
        if (window.gapi.client.drive) {
            return window.gapi.client.drive.files.list({
                'spaces': "appDataFolder"
            }).then((res) => {
                var subFile = res.result.files.find((f) => f.name == 'subscription.json')
                if (subFile) {
                    return window.gapi.client.drive.files.get({
                        'fileId': subFile.id,
                        'alt': "media"
                    }).then((file) => {
                        subscriptId = JSON.parse(file.body).subscriptionId
                        return JSON.parse(file.body).subscriptionId

                        // call the paypal api
                    })
                } else {
                    throw -1
                }
            })
        }
    },
    createScenario: (name, from) => {
        return loadScenario(from).then((s) => {
            return saveJson(s, name)
        })
    },
    getAnnualIncome: (finances, year) => {
        var income = [...getIncomes(finances)]

        income = income.filter((f) => f.periods.find((s) => dayjs(s.start, 'YYYY-MM-DD').year() <= year))

        // You can't take money out of savings if the money isn't in your savings
        for (var i = 0; i < income.length; i++) {
            var savings = finances.savings.find((f) => f.id == income[i].from)
            if (savings) {
                var returns = calculateSavings(finances, savings, true)
                // TODO someone need to notify this to the user
                var burnout = returns.reduce((prev, cur) => cur.total < 0 && cur.year < prev ? cur.year : prev, year)
                if (burnout < year) {
                    income.splice(i, 1)
                }
            }
        }

        // if taking pension, filter our salary
        income = income.filter((f) => income.find((i) => i.incomeId == f.id) == undefined)

        var whos = new Set(income.map((m) => m.who))

        var annualIncome = {
            incomes: []
        }

        whos.forEach((who) => {
            var taxableIncome = 0
            var niAbleIncome = 0
            var nonTaxableIncome = 0

            var incomes = []

            for (var i = 0; i < income.length; i++) {
                if (income[i].who === who) {
                    var rel = income[i].periods.filter((p) => year >= dayjs(p.start, 'YYYY-MM-DD').year() && (p.end == null || year <= dayjs(p.end, 'YYYY-MM-DD').year()))

                    if (rel.length > 0) {
                        var inc = {
                            id: income[i].id,
                            name: income[i].name,
                            gross: 0,
                            pensionContribution: 0,
                            type: income[i].type,
                            taxable: income[i].taxable
                        }
                        // may be multiple periods in same year
                        rel.forEach((p) => {
                            //
                            var multiplier = 1

                            if (dayjs(p.start, 'YYYY-MM-DD').year() == year && dayjs(p.start, 'YYYY-MM-DD').month() > 0) {
                                if (dayjs(p.end, 'YYYY-MM-DD').year() == year) {
                                    // start date isn't jan and ends in same year
                                    multiplier = ((dayjs(p.end, 'YYYY-MM-DD').month() - dayjs(p.start, 'YYYY-MM-DD').month()) + 1) / 12
                                } else {
                                    // doesn't end in same year so go to end of year
                                    multiplier = (12 - (dayjs(p.start, 'YYYY-MM-DD').month() + 1)) / 12
                                }
                            } else if (dayjs(p.end, 'YYYY-MM-DD').year() == year && dayjs(p.end, 'YYYY-MM-DD').month() < 11) {
                                if (dayjs(p.start, 'YYYY-MM-DD').year() == year) {
                                    // we're in the closing year but also starts in the year
                                    multiplier = ((dayjs(p.end, 'YYYY-MM-DD').month() - dayjs(p.start, 'YYYY-MM-DD').month()) + 1) / 12
                                } else {
                                    // doesn't start in this year so from jan
                                    multiplier = (dayjs(p.end, 'YYYY-MM-DD').month() + 1) / 12
                                }
                            }

                            var inflationAdjustedIncome = p.gross

                            // apply wage inflation
                            if (income[i].inflate) {
                                for (var x = dayjs(p.start, 'YYYY-MM-DD').year(); x < year; x++) {
                                    inflationAdjustedIncome = inflationAdjustedIncome * settings.inflation
                                }
                            }

                            inflationAdjustedIncome = inflationAdjustedIncome * multiplier

                            inc.gross += inflationAdjustedIncome
                            var pensionCont = 0

                            if (income[i] && income[i].pension?.type != "none") {
                                pensionCont = p.employeeContribution ? inflationAdjustedIncome * p.employeeContribution : 0

                                inc.pensionContribution += pensionCont
                            }

                            inflationAdjustedIncome -= pensionCont

                            if (income[i].taxable) {
                                taxableIncome += inflationAdjustedIncome

                                if (income[i].from == 'employer') {
                                    niAbleIncome += inflationAdjustedIncome
                                }
                            } else {
                                nonTaxableIncome += inflationAdjustedIncome
                            }

                        })

                        incomes.push(inc)
                    }

                }
            }

            var basicTax = 0;
            var higherTax = 0;
            var basicNationalInsurance = 0;
            var higherNationalInsurance = 0;


            var personalAllowance = settings.tax.personalAllowance.find((f) => year >= f.start && (f.end == null || year <= f.end))

            var personalAllowanceAmount = personalAllowance.amount

            for (var i = personalAllowance.start; i < year; i++) {
                personalAllowanceAmount *= settings.tax.inflation
            }

            var upperThreshold = settings.tax.upperThreshold.find((f) => year >= f.start && (f.end == null || year <= f.end))
            var upperThresholdAmount = upperThreshold.amount

            for (var i = upperThreshold.start; i < year; i++) {
                upperThresholdAmount *= settings.tax.inflation
            }

            if (taxableIncome > upperThresholdAmount) {
                basicTax += (upperThresholdAmount - personalAllowanceAmount) * settings.tax.basicTaxRate.find((f) => year >= f.start && (f.end == null || year <= f.end)).percentage
                higherTax += (taxableIncome - upperThresholdAmount) * settings.tax.upperTaxRate.find((f) => year >= f.start && (f.end == null || year <= f.end)).percentage

                if (niAbleIncome > personalAllowanceAmount) {
                    basicNationalInsurance += (upperThresholdAmount - personalAllowanceAmount) * settings.tax.basicNIRate.find((f) => year >= f.start && (f.end == null || year <= f.end)).percentage
                    higherNationalInsurance += (niAbleIncome - upperThresholdAmount) * settings.tax.upperNIRate.find((f) => year >= f.start && (f.end == null || year <= f.end)).percentage
                }

            } else if (taxableIncome > personalAllowanceAmount) {
                basicTax += (taxableIncome - personalAllowanceAmount) * settings.tax.basicTaxRate.find((f) => year >= f.start && (f.end == null || year <= f.end)).percentage

                if (niAbleIncome > personalAllowanceAmount) {
                    basicNationalInsurance += (niAbleIncome - personalAllowanceAmount) * settings.tax.basicNIRate.find((f) => year >= f.start && (f.end == null || year <= f.end)).percentage
                }
            }

            annualIncome.incomes.push({
                who: who,
                incomes: incomes,
                totalGrossIncome: incomes.reduce((prev, cur) => prev + cur.gross, 0),
                totalGrossTaxableIncomeBeforeDeductions: incomes.filter((f) => f.taxable).reduce((prev, cur) => prev + cur.gross, 0),
                totalGrossTaxableIncomeAfterDeductions: taxableIncome,
                totalNetNonTaxableIncome: nonTaxableIncome,
                totalTaxableTakeHome: taxableIncome - basicTax - higherTax - basicNationalInsurance - higherNationalInsurance,
                totalNetIncome: nonTaxableIncome + (taxableIncome - basicTax - higherTax - basicNationalInsurance - higherNationalInsurance),
                personalAllowance: personalAllowanceAmount,
                basicTax: basicTax,
                higherTax: higherTax,
                upperThreshold: upperThresholdAmount,
                basicNationalInsurance: basicNationalInsurance,
                higherNationalInsurance: higherNationalInsurance,
                pensionContribution: incomes.reduce((prev, cur) => prev + cur.pensionContribution, 0)
            })
        })

        var savingsDeposits = finances.savings.reduce((prev, cur) => {
            var deposit = cur.deposits?.find((f) => f.from == 'net' && year >= f.start && (f.end == null || year <= f.end))
            return deposit ? prev + deposit.amount : prev
        }, 0)

        annualIncome['savingsDeposits'] = savingsDeposits
        annualIncome['net'] = annualIncome.incomes.reduce((prev, cur) => prev + cur.totalNetIncome, 0) - savingsDeposits
        annualIncome['gross'] = annualIncome.incomes.reduce((prev, cur) => prev + cur.totalGrossIncome, 0)


        return annualIncome

    },
    getIncomeAfterExpenses: (finances, year) => {
        return finances.annualIncome[year].net - getNetExpenseAnnualTotal(finances, year) - getLoanAnnualTotal(finances, year) - getCreditAnnualTotal(finances, year) - getSavingsAnnualTotal(finances, year)
    },
    getCreditAnnualTotal: (finances, year) => {
        return finances.credit.reduce((prev, cur) => {
            var repayments = calculateRepayments(finances, cur)
            return Array.isArray(repayments) ? repayments.find((f) => f.year == year) ? prev + repayments.find((f) => f.year == year).payments : prev : prev
        }, 0)
    },
    getLoanAnnualTotal: (finances, year) => {
        return finances.loans.reduce((prev, cur) => {
            var repayments = calculateRepayments(finances, cur)
            return repayments.find((f) => f.year == year) ? prev + repayments.find((f) => f.year == year).payments : prev
        }, 0)
    },
    getNetExpenseAnnualTotal: (finances, year) => {
        return finances.expenses.filter((f) => f.periods.findIndex((p) => p.pay == 'net') > -1).reduce((prev, cur) => {
            var res = getExpenseAnnualTotal(finances, year, cur, 'net')
            return prev + (res > -1 ? res : 0)
        }, 0)
    },
    getExpenseAnnualTotal: (finances, year, expense, pay) => {

        var expenses = expense ? [expense] : finances.expenses

        var total = 0

        expenses.forEach((e) => {
            var period = e.periods.find((f) => year >= dayjs(f.start, 'YYYY-MM-DD').year() && (year <= dayjs(f.end, 'YYYY-MM-DD').year() || f.end == null))

            if (period && ((pay && pay == period.pay) || !pay)) {
                var tmp = period.amount

                if (e.inflate) {
                    for (var i = dayjs(period.start, 'YYYY-MM-DD').year(); i < year; i++) {
                        tmp *= settings.inflation
                    }
                }

                total += tmp
            }
        })

        return total

    },
    getSavingsAnnualTotal: (finances, year) => {
        return getSavingsIncludingPension(finances).reduce((prev, cur) => {
            var saving = cur.periods.find((f) => year >= dayjs(f.start, 'YYYY-MM-DD').year() && (year <= dayjs(f.end, 'YYYY-MM-DD').year() || f.end == null))
            if (saving) {
                if (saving.monthly != null) {
                    return prev + (saving.monthly * 12)
                } else {
                    return prev + (finances.annualIncome[year].net * saving.percent)
                }
            }

            return prev
        }, 0)
    },
    getLoanMonthlyPayment: (finances, loan, cur) => {
        var periods = {}

        for (var i = 0; i < loan.periods.length; i++) {
            var left = loan.periods[i].duration
            var startAmount = loan.periods[i].startAmount ? loan.periods[i].startAmount : cur.find((f) => f.year == (dayjs(loan.periods[i].start, 'YYYY-MM-DD').year() - 1))?.endAmount

            if (loan.periods[i].apr == 0) {
                periods[loan.periods[i].start] = (startAmount / left).toFixed(2)
            } else {
                periods[loan.periods[i].start] = ((startAmount * ((loan.periods[i].apr / 12) * ((1 + (loan.periods[i].apr / 12)) ** left)) / (((1 + (loan.periods[i].apr / 12)) ** left) - 1)))
            }

        }

        return periods

        //console.log("MONTHLY PAY", Math.round((loan.periods[0].startAmount * ((loan.periods[0].apr / 12) * ((1 + (loan.periods[0].apr / 12)) ** loan.duration))) / (((1 + (loan.periods[0].apr / 12)) ** loan.duration) - 1)))

        //return Math.round((loan.periods[0].startAmount * ((loan.periods[0].apr / 12) * ((1 + (loan.periods[0].apr / 12)) ** loan.duration))) / (((1 + (loan.periods[0].apr / 12)) ** loan.duration) - 1))

    },
    getIncomes: (finances) => {

        return [...finances.income, ...getSavingsIncludingPension(finances).filter((f) => f.type == 'pension').flatMap((p) => {
            if (finances.personal?.dob[p.who]) {
                var pension = calculateSavings(finances, p, true)

                var income = {
                    id: p.id,
                    who: p.who,
                    type: 'pension',
                    incomeId: p.incomeId,
                    name: p.name,
                    taxable: true,
                    periods: []
                }

                var pensionYearOne = pension.find((f) => f.year == dayjs(finances.personal.dob[p.who], 'YYYY-MM-DD').year() + finances.income.find((i) => i.id == income.incomeId).pension.takePensionAge)
                var pensionLumpSum = pensionYearOne.total * finances.income.find((f) => f.id == p.incomeId).pension.lumpSum
                // TODO how long you want to take pension for should be configurable
                var remainingAnnualPension = (pensionYearOne.total - pensionLumpSum) / 20

                income.periods.push({
                    start: dayjs(finances.personal.dob[p.who], 'YYYY-MM-DD').add(finances.income.find((i) => i.id == income.incomeId).pension.takePensionAge, 'years').date(1).month(0).format('YYYY-MM-DD'),
                    end: (dayjs(finances.personal.dob[p.who], 'YYYY-MM-DD').add(finances.income.find((i) => i.id == income.incomeId).pension.takePensionAge + 20, 'years').date(1).month(0).format('YYYY-MM-DD')),
                    gross: remainingAnnualPension
                })

                return [income, {
                    id: `${p.id}-lump-sum`,
                    who: p.who,
                    incomeId: p.incomeId,
                    type: 'pension',
                    subtype: 'lumpsum',
                    name: `${p.name} Lump Sum`,
                    taxable: false, // lump sum up to 25% is tax-free
                    periods: [{
                        start: dayjs(finances.personal.dob[p.who], 'YYYY-MM-DD').add(finances.income.find((i) => i.id == income.incomeId).pension.takePensionAge, 'years').date(1).month(0).format('YYYY-MM-DD'),
                        end: dayjs(finances.personal.dob[p.who], 'YYYY-MM-DD').add(finances.income.find((i) => i.id == income.incomeId).pension.takePensionAge, 'years').month(11).date(31).format('YYYY-MM-DD'),
                        gross: pensionLumpSum
                    }]
                }]
            }

            return []

        }), ...Array.from(new Set(finances.income.map((m) => m.who))).map((m) => {
            return {
                id: `${m}-publicpension`,
                who: m,
                type: 'pension',
                subtype: 'public',
                name: `State Pension (${m})`,
                taxable: true,
                inflate: true,
                periods: [
                    {
                        start: dayjs(finances.personal?.dob[m], 'YYYY-MM-DD').month(0).date(1).add(settings.pension.statePensionAge, 'years'),
                        end: null,
                        // TODO add in state pensions based on contributing years
                        gross: calculateInflatedValue(11000, (dayjs(finances.personal?.dob[m], 'YYYY-MM-DD').year() + settings.pension.statePensionAge) - 2024, settings.inflation),
                        pensionContribution: 0
                    }
                ]
            }
        })]
    },
    reallocateExpensesToNetForId: (finances, id) => {
        finances.expenses.forEach((e) => {
            e.periods.filter((p) => p.pay == id).forEach((p) => p.pay = 'net')
        })
    },
    getSavingsIncludingPension: (finances) => {
        var savings = [...finances.savings]

        var t = ["you", "partner"]

        t.forEach((who) => {

            if (finances.personal?.dob[who]) {

                finances.income.filter((f) => f.who == who && f.pension?.type == 'definedcontribution').forEach((i) => {
                    var pen = {
                        id: `${i.who}-employerspension-${i.id}`,
                        incomeId: i.id,
                        name: `Workplace Pension (${who == 'you' ? 'You' : 'Partner'})`,
                        type: 'pension',
                        who: i.who,
                        periods: []
                    }

                    var retirementYear = dayjs(finances.personal.dob[i.who], 'YYYY-MM-DD').year() + i.pension.takePensionAge

                    for (var p = 0; p < i.periods.length; p++) {

                        // if there's a gap, fill it in
                        if (p > 0 && dayjs(i.periods[p].start, 'YYYY-MM-DD').year() != (dayjs(pen.periods[pen.periods.length - 1].end, 'YYYY-MM-DD').year() + 1)) {
                            pen.periods.push({
                                start: `${dayjs(pen.periods[pen.periods.length - 1].end, 'YYYY-MM-DD').year() + 1}-01-01`,
                                end: `${dayjs(i.periods[p].start, 'YYYY-MM-DD').year() - 1}-12-31`,
                                monthly: 0,
                                interest: retirementYear - y.year() > 20 ? 0.08 : retirementYear - y.year() > 0 ? 0.06 : 0
                            })
                        }

                        if (i.periods[p].start) {
                            var inflatedWage = i.periods[p].gross
                            var pStart = dayjs(i.periods[p + 1]?.start, 'YYYY-MM-DD')

                            for (var y = dayjs(i.periods[p].start, 'YYYY-MM-DD'); (i.periods[p].end && y < dayjs(i.periods[p].end, 'YYYY-MM-DD')) || (!i.periods[p].end && y < pStart || ((p == i.periods.length - 1 || !i.periods[i.periods.length - 1].start) && y.year() <= retirementYear)); y = y.add(1, 'year')) {
                                pen.periods.push({
                                    start: `${y.year()}-01-01`,
                                    end: `${y.year()}-12-31`,
                                    monthly: (inflatedWage * (i.periods[p].employeeContribution + i.periods[p].employerContribution)) / 12,
                                    interest: retirementYear - y.year() > 20 ? 0.08 : retirementYear - y.year() > 0 ? 0.06 : 0
                                })

                                if (i.periods[p].startingPensionSize && y.year() == dayjs(i.periods[p].start, 'YYYY-MM-DD').year()) {
                                    pen.periods[pen.periods.length - 1].amount = i.periods[p].startingPensionSize
                                }

                                inflatedWage = inflatedWage * settings.inflation

                            }
                        }

                    }

                    // final period there's no invesments
                    pen.periods.push({
                        start: dayjs().year(retirementYear + 1),
                        end: null,
                        monthly: 0,
                        interest: 0
                    })

                    if (!pen.periods[0].amount) {
                        pen.periods[0].amount = 0
                    }

                    savings.push(pen)
                })
            }
        })


        return savings
    },
    calculateRepayments: (finances, credit, year) => {
        var years = []
        //credit.periods = credit.periods.filter((f) => f.start != null)
        //credit.periods.sort((a,b) => a.start - b.start)

        var lastDate = finances.expenses.reduce((p, c) => {
            var l = c.periods.reduce((pre, cu) => {
                if (cu.pay == credit.id) {
                    if (cu.end == null) {
                        // TODO static date
                        return 2100
                    } else if (dayjs(cu.end, 'YYYY-MM-DD').year() > pre) {
                        return dayjs(cu.end, 'YYYY-MM-DD').year()
                    }
                }

                return pre
            }, 0)
            return l > p ? l : p
        }, 0)

        // found the starting point for the calculation
        var loanAmount = credit.periods[0].startAmount
        for (var z = 0; z < credit.periods.length; z++) {
            var loops = 0
            if (credit.periods[z].start) {

                var calculatedMontlyPayment = getLoanMonthlyPayment(finances, credit, years)[credit.periods[z].start]
                if (credit.periods[z].startAmount != null) {
                    loanAmount = credit.periods[z].startAmount
                    if (loanAmount == 0 && years[years.length - 1]) {
                        years[years.length - 1].endAmount = 0
                    }
                }

                var prevYear = null
                var amountTmp = loanAmount
                var yearPaid = 0
                var interest = 0
                var months = 0

                for (var y = dayjs(credit.periods[z].start, 'YYYY-MM-DD'); y <= dayjs().year(2200) && ((y <= dayjs(credit.periods[z].end, "YYYY-MM-DD") || !credit.periods[z].end) && (y <= dayjs().year(lastDate) || loanAmount > 0)); y = y.add(1, 'month').set('date', 1)) {
                    if (prevYear != null && y.year() > prevYear) {
                        loops++
                        // need to take into account existing year here as well.. in this one though, the loan isn't yet repaid as below
                        var existingYear = years.find((f) => f.year == prevYear)

                        if (existingYear) {
                            existingYear.endAmount = loanAmount
                            existingYear.months += months
                            existingYear.interest += interest
                            existingYear.payments += yearPaid
                            existingYear.totalInterest += interest
                        } else {
                            years.push({
                                year: prevYear,
                                startAmount: amountTmp,
                                endAmount: loanAmount,
                                months: months,
                                interest: interest,
                                totalInterest: years.length > 0 ? years[years.length - 1].totalInterest + interest : interest,
                                payments: yearPaid
                            })
                        }

                        amountTmp = loanAmount
                        yearPaid = 0
                        interest = 0
                        // new year so update years


                        months = 0
                    }

                    months++

                    prevYear = y.year()

                    var studentLoanReferenceData = settings.studentloan.find((f) => credit.subtype == f.type && y.year() >= f.start && (f.end == null || y.year() <= f.end))

                    // find any expenses to add on
                    var expenseAnnual = finances.expenses.reduce((pE, cU) => {
                        return pE + cU.periods.filter((p) => p.pay != null && p.pay == credit.id && y.year() >= dayjs(p.start, 'YYYY-MM-DD').year() && (p.end == null || y.year() <= dayjs(p.end, 'YYYY-MM-DD').year())).reduce((prev, cur) => {
                            return prev + calculateInflatedValue(cur.amount, y.year() - dayjs(cur.start, 'YYYY-MM-DD').year(), settings.inflation)
                        }, 0)
                    }, 0)

                    // for every year in the period, calculate

                    loanAmount += expenseAnnual / 12

                    var apr = credit.type == 'student' ? studentLoanReferenceData?.interest : credit.periods[z].apr

                    var monthlyPayment = 0
                    //var lInterest = loanAmount * (apr / 12)

                    if (credit.type === 'student') {
                        // TODO handle multiple incomes
                        var grossIncome = finances.annualIncome[y.year()].incomes.find((f) => f.who == credit.who)?.totalGrossTaxableIncomeBeforeDeductions

                        var inflatedThreshold = calculateInflatedValue(studentLoanReferenceData.threshold, dayjs().year() - studentLoanReferenceData.start, 1.01)
                        if (grossIncome > inflatedThreshold) {
                            monthlyPayment = credit.periods[z].monthly ? credit.periods[z].monthly : (((grossIncome - inflatedThreshold) * studentLoanReferenceData.percent) / 12)
                        } else {
                            monthlyPayment = 0
                        }

                    } else {
                        if (credit.periods[z].interestonly) {
                            monthlyPayment = loanAmount * (apr / 12)
                        } else {
                            monthlyPayment = (credit.periods[z].monthly >= 0 && credit.periods[z].monthly != null) ? credit.periods[z].monthly : calculatedMontlyPayment
                        }
                    }


                    var deduction = parseFloat(monthlyPayment) - (credit.periods[z].addition ? credit.periods[z].addition : 0)

                    if ((loanAmount - deduction) <= 1) {
                        // pay off what's left
                        yearPaid += loanAmount
                        loanAmount = 0
                        // where the loan has ended in an existing year with a period crossover

                        if (existingYear) {
                            existingYear.endAmount = loanAmount
                            existingYear.months += months
                            existingYear.interest += interest
                            existingYear.payments += yearPaid
                            existingYear.totalInterest += interest
                        } else {
                            years.push({
                                year: y.year(),
                                startAmount: amountTmp,
                                endAmount: loanAmount,
                                months: months,
                                interest: interest,
                                totalInterest: years.length > 0 ? years[years.length - 1].totalInterest + interest : interest,
                                payments: yearPaid
                            })
                        }
                        // can't return years because credit needs to continue
                        //return years
                    } else {
                        yearPaid += parseFloat(monthlyPayment)
                        interest += loanAmount * (apr / 12)
                        loanAmount += loanAmount * (apr / 12)
                        loanAmount -= deduction
                    }

                    if ((credit.type == 'student' && loops >= 30) || loops > 100) {
                        return years
                    }


                }

                // this one handles if the loan/credit isn't paid off
                if (loanAmount > 0) {
                    years.push({
                        year: prevYear,
                        startAmount: amountTmp,
                        endAmount: loanAmount,
                        months: months,
                        interest: interest,
                        totalInterest: years.length > 0 ? years[years.length - 1].totalInterest + interest : interest,
                        payments: yearPaid
                    })
                }
            }
        }
        return years
    },
    calculateInflatedValue: (value, years, inflation) => {
        if (inflation == 0) {
            return value
        }

        for (var i = 0; i < years; i++) {
            value = value * inflation
        }

        return value
    },
    calculateSavings: (finances, savings, incomeCalc) => {

        // TODO document the purpose of the incomecalc boolean

        var years = []
        var amount = savings.periods[0].amount

        for (var i = 0; i < savings.periods.length; i++) {
            if (savings.periods[i].start) {
                amount = savings.periods[i].amount ? savings.periods[i].amount : amount

                // TODO this type of loop doesn't work when savings amounts changes mid year
                for (var x = dayjs(savings.periods[i].start, 'YYYY-MM-DD'); x <= dayjs(savings.periods[i].end) || (x.year() < 2100 && !savings.periods[i].end); x = x.add(1, 'year')) {
                    // find any expenses to add on

                    // add any deposits
                    var depAmount = savings.deposits?.filter((f) => x.year() >= f.start && (f.end == null || x.year() <= f.end)).reduce((prev, cur) => {
                        return prev + cur.amount
                    }, 0)
                    if (depAmount) amount += depAmount

                    // is the account depositing into any other savings accounts?
                    for (var s = 0; s < finances.savings.length; s++) {
                        var dep = finances.savings[s].deposits?.find((f) => f?.from == savings.id && x.year() >= f.start && (x.year() <= f.end || f.end == null))
                        amount -= dep ? dep.amount : 0
                    }

                    // TODO - doesn't account for expense starting midway through year?
                    amount -= finances.expenses.reduce((pE, cU) => {
                        return pE + cU.periods.filter((p) => p.pay == savings.id && x.year() >= dayjs(p.start, 'YYYY-MM-DD').year() && (p.end == null || x.year() <= dayjs(p.end, 'YYYY-MM-DD').year())).reduce((prev, cur) => {
                            return prev + calculateInflatedValue(cur.amount, x.year() - cur.start, settings.inflation)
                        }, 0)
                    }, 0)

                    // taking an annual amount from savings?
                    amount -= finances.income.filter((f) => f.from == savings.id).reduce((prev, cur) => {
                        var val = cur.periods.find((f) => x >= dayjs(f.start, 'YYYY-MM-DD') && (x <= dayjs(f.end, 'YYYY-MM-DD') || f.end == null))
                        return val ? prev + val.gross : prev
                    }, 0)

                    // Is your pension now paying out money?
                    if (!incomeCalc && savings.incomeId) {
                        var inc = getIncomes(finances)
                        var inco = inc.filter((f) => savings.incomeId == f.incomeId && f.type == 'pension')
                        var pensionAmount = inco?.reduce((prev, cur) => {
                            var gross = cur?.periods.find((f) => x >= dayjs(f.start, 'YYYY-MM-DD') && (x <= dayjs(f.end, 'YYYY-MM-DD') || f.end == null))?.gross
                            return gross ? prev + gross : prev
                        }, 0)

                        if (pensionAmount) {
                            amount -= pensionAmount
                        }

                        if (amount < 0) {
                            amount = 0
                        }
                    }

                    var earnedInterest = 0

                    var contribution = savings.periods[i].monthly != null && savings.periods[i].monthly >= 0 ? savings.periods[i].monthly : (finances.annualIncome[x.year()]?.net * savings.periods[i].percent) / 12

                    for (var y = 0; y < 12; y++) {
                        var interest = amount * (savings.periods[i].interest / 12)
                        earnedInterest += interest > 0 ? interest : 0
                        amount += contribution + interest
                    }

                    years.push({
                        year: x.year(),
                        interest: earnedInterest,
                        total: amount
                    })
                }
            }

        }

        return years

    },
    saveJson: (data, name) => {
        return saveJsonGoogleDrive(name, data, fileId)
    },
    saveJsonGoogleDrive: (filename, data, fileIdentifier) => {
        return new Promise((resolve, reject) => {
            var file = new Blob([JSON.stringify(data)], { type: 'application/json' });
            var metadata = {
                'mimeType': 'application/json' // mimeType at Google Drive
                // TODO [Optional]: Set the below credentials
                // Note: remove this parameter, if no target is needed
            };

            var accessToken = window.gapi.auth.getToken()?.access_token; // Here gapi is used for retrieving the access token.

            if (accessToken) {
                var form = new FormData();

                var xhr = new XMLHttpRequest();

                if (fileIdentifier && !filename) {
                    xhr.open('PATCH', `https://www.googleapis.com/upload/drive/v3/files/${fileIdentifier}?uploadType=multipart&fields=id&fileId=${fileIdentifier}`);
                    metadata['fileId'] = fileIdentifier

                } else {
                    xhr.open('POST', 'https://www.googleapis.com/upload/drive/v3/files?uploadType=multipart&fields=id');
                    metadata['parents'] = ['appDataFolder']
                    metadata['name'] = `${filename ? filename : "Main"}.json` // Filename at Google Drive
                }

                form.append('metadata', new Blob([JSON.stringify(metadata)], { type: 'application/json' }));
                form.append('file', file);

                xhr.setRequestHeader('Authorization', 'Bearer ' + accessToken);
                xhr.responseType = 'json';
                xhr.send(form);

                xhr.onload = () => {
                    if (xhr.status !== 200) {
                        window.sessionStorage.clear()
                        window.location.reload()
                    }
                    resolve()
                }

                xhr.onerror = () => {
                    window.sessionStorage.clear()
                    window.location.reload()
                }
            } else {
                reject("no token")
            }
        })
    },
    deleteJson: (id) => {
        return window.gapi.client.drive.files.delete({
            'fileId': id
        }).then((file) => {
            console.log("done")
        })
    },
    loadScenarios: () => {
        return window.gapi.client.drive.files.list({
            'spaces': "appDataFolder"
        }).then((r) => {
            return r.result.files.filter((f) => f.name != 'subscription.json')
        })
    },
    loadScenario: (id) => {
        return window.gapi.client.drive.files.get({
            'fileId': id,
            'alt': "media"
        }).then((file) => {
            var res = JSON.parse(file.body)

            console.log("response", res)

            var types = ["income", "expenses", 'savings', 'loans', 'credit']

            res.loans.forEach((l) => {
                l.periods.forEach((p) => {
                    if (!p.duration) {
                        p.duration = l.duration
                    }
                })
            })

            types.forEach((t) => {
                res[t] && res[t].forEach((i) => {
                    i.periods.forEach((p) => {
                        if (!isNaN(p.start)) {
                            p.start = dayjs().year(p.start).month(0).date(1).format('YYYY-MM-DD')
                        }
                        if (p.end && !isNaN(p.end)) {
                            p.end = dayjs().year(p.end).month(11).date(31).format('YYYY-MM-DD')
                        }
                    })
                })
            })




            return res
        })

    },
    setFinances: (fin, fId) => {
        fileId = fId
    }

}

export var demoFinance = {
    "income": [
        {
            "name": "Job",
            "from": "employer",
            "id": "job",
            "who": "you",
            "taxable": true,
            "inflate": true,
            "pension": {
                "type": "definedcontribution",
                "lumpSum": 0.25,
                "takePensionAge": 65
            },
            "periods": [
                {
                    "start": "2024-01-01",
                    "end": null,
                    "gross": 27000,
                    "employeeContribution": 0.06,
                    "employerContribution": 0.03
                }
            ]
        },
        {
            "name": "Partner",
            "from": "employer",
            "id": "partner",
            "who": "partner",
            "taxable": true,
            "inflate": true,
            "pension": {
                "type": "definedcontribution",
                "lumpSum": 0.25,
                "takePensionAge": 65
            },
            "periods": [
                {
                    "start": "2024-01-01",
                    "end": "2032-12-01",
                    "gross": 25000,
                    "employeeContribution": 0.05,
                    "employerContribution": 0.03
                },
                {
                    "start": "2039-01-01",
                    "end": null,
                    "gross": 25000,
                    "employeeContribution": 0.05,
                    "employerContribution": 0.03
                }
            ]
        },
        {
            "name": "Child Benefit",
            "taxable": false,
            "periods": [
                {
                    "start": "2033-01-01",
                    "end": "2038-12-01",
                    "gross": 1500
                }
            ],
            "pension": {
                "takePensionAge": 65,
                "lumpSum": 0.2
            },
            "who": "you",
            "from": "other",
            "inflate": true,
            "id": "Child Benefit"
        },
        {
            "name": "Maternity",
            "taxable": false,
            "periods": [
                {
                    "start": "2033-01-01",
                    "end": "2033-12-01",
                    "gross": 7000
                },
                {
                    "start": "2035-01-01",
                    "end": "2035-12-01",
                    "gross": 7000
                }
            ],
            "pension": {
                "takePensionAge": 65,
                "lumpSum": 0.2
            },
            "who": "partner",
            "from": "other",
            "id": "Maternity"
        },
        {
            "name": "Income Buffer",
            "taxable": false,
            "periods": [
                {
                    "start": "2033-01-01",
                    "end": "2038-12-01",
                    "gross": 12000
                }
            ],
            "pension": {
                "takePensionAge": 65,
                "lumpSum": 0.2
            },
            "who": "you",
            "from": "savings",
            "id": "Income Buffer"
        },
        {
            "name": "Part Time",
            "taxable": true,
            "periods": [
                {
                    "start": "2034-01-01",
                    "end": "2034-12-01",
                    "gross": 3600
                },
                {
                    "start": "2036-01-01",
                    "end": "2038-12-01",
                    "gross": 3600
                }
            ],
            "pension": {
                "takePensionAge": 65,
                "lumpSum": 0.2,
                "type": "none"
            },
            "who": "partner",
            "from": "employer",
            "inflate": true,
            "id": "Part Time"
        }
    ],
    "expenses": [
        {
            "id": "rent",
            "inflate": true,
            "type": "rent",
            "periods": [
                {
                    "start": "2024-01-01",
                    "pay": "net",
                    "end": "2030-12-01",
                    "amount": 10200
                }
            ]
        },
        {
            "id": "childcare",
            "inflate": true,
            "type": "childcare",
            "periods": [
                {
                    "start": "2033-01-01",
                    "pay": "net",
                    "end": "2038-12-01",
                    "amount": 0
                }
            ]
        },
        {
            "name": "energy",
            "inflate": true,
            "type": "energy",
            "periods": [
                {
                    "pay": "net",
                    "start": "2024-01-01",
                    "end": null,
                    "amount": 1440
                }
            ],
            "id": "energy"
        },
        {
            "name": "broadband",
            "inflate": true,
            "type": "broadband",
            "periods": [
                {
                    "pay": "net",
                    "start": "2024-01-01",
                    "end": null,
                    "amount": 480
                }
            ],
            "id": "broadband"
        },
        {
            "name": "fuel",
            "inflate": true,
            "type": "fuel",
            "periods": [
                {
                    "pay": "net",
                    "start": "2024-01-01",
                    "end": null,
                    "amount": 2000
                }
            ],
            "id": "fuel"
        },
        {
            "name": "carinsurance",
            "inflate": true,
            "type": "carinsurance",
            "periods": [
                {
                    "pay": "net",
                    "start": "2024-01-01",
                    "end": null,
                    "amount": 1600
                }
            ],
            "id": "carinsurance"
        },
        {
            "name": "clothing",
            "inflate": true,
            "type": "clothing",
            "periods": [
                {
                    "pay": "net",
                    "start": "2024-01-01",
                    "end": "2032-12-01",
                    "amount": 2000
                },
                {
                    "pay": "net",
                    "start": "2033-01-01",
                    "end": null,
                    "amount": 3500
                }
            ],
            "id": "clothing"
        },
        {
            "name": "counciltax",
            "inflate": true,
            "type": "counciltax",
            "periods": [
                {
                    "pay": "net",
                    "start": "2024-01-01",
                    "end": null,
                    "amount": 1560
                }
            ],
            "id": "counciltax"
        },
        {
            "name": "homeinsurance",
            "inflate": true,
            "type": "homeinsurance",
            "periods": [
                {
                    "pay": "net",
                    "start": "2024-01-01",
                    "end": null,
                    "amount": 200
                }
            ],
            "id": "homeinsurance"
        },
        {
            "name": "phone",
            "inflate": true,
            "type": "phone",
            "periods": [
                {
                    "pay": "net",
                    "start": "2024-01-01",
                    "end": null,
                    "amount": 720
                }
            ],
            "id": "phone"
        },
        {
            "name": "shopping",
            "inflate": true,
            "type": "shopping",
            "periods": [
                {
                    "pay": "net",
                    "start": "2024-01-01",
                    "end": "2032-12-01",
                    "amount": 3600
                },
                {
                    "pay": "net",
                    "start": "2033-01-01",
                    "end": null,
                    "amount": 8000
                }
            ],
            "id": "shopping"
        },
        {
            "name": "water",
            "inflate": true,
            "type": "water",
            "periods": [
                {
                    "pay": "net",
                    "start": "2024-01-01",
                    "end": null,
                    "amount": 100
                }
            ],
            "id": "water"
        },
        {
            "name": "",
            "inflate": false,
            "type": "other",
            "periods": [
                {
                    "pay": "LISA",
                    "start": "2031-01-01",
                    "end": "2031-12-01",
                    "amount": 21000
                }
            ],
            "customname": "Deposit",
            "id": "Deposit"
        },
        {
            "name": "holiday",
            "inflate": true,
            "type": "holiday",
            "periods": [
                {
                    "pay": "savings",
                    "start": "2024-01-01",
                    "end": "2030-12-01",
                    "amount": 1000
                },
                {
                    "pay": "net",
                    "start": "2033-01-01",
                    "end": "2039-12-01",
                    "amount": 0
                },
                {
                    "pay": "savings",
                    "start": "2040-01-01",
                    "end": "2055-12-01",
                    "amount": 1500
                },
                {
                    "pay": "savings",
                    "start": "2056-01-01",
                    "end": "2068-01-01",
                    "amount": 5000
                },
                {
                    "pay": "savings",
                    "start": "2069-01-01",
                    "end": null,
                    "amount": 10000
                }
            ],
            "id": "holiday"
        },
        {
            "name": "christmas",
            "inflate": true,
            "type": "christmas",
            "periods": [
                {
                    "pay": "savings",
                    "start": "2024-01-01",
                    "end": null,
                    "amount": 400
                }
            ],
            "id": "christmas"
        },
        {
            "name": "",
            "inflate": true,
            "type": "other",
            "periods": [
                {
                    "pay": "savings",
                    "start": "2028-08-01",
                    "end": "2028-12-01",
                    "amount": 4000
                }
            ],
            "customname": "Car",
            "id": "Car"
        },
        {
            "name": "subscriptions",
            "inflate": true,
            "type": "subscriptions",
            "periods": [
                {
                    "pay": "net",
                    "start": "2024-01-01",
                    "end": null,
                    "amount": 1200
                }
            ],
            "id": "subscriptions"
        },
        {
            "name": "",
            "inflate": false,
            "type": "other",
            "periods": [
                {
                    "pay": "savings",
                    "start": "2038-01-01",
                    "end": "2038-12-01",
                    "amount": 10000
                }
            ],
            "customname": "Family Car",
            "id": "Family Car"
        },
        {
            "name": "",
            "inflate": true,
            "type": "other",
            "periods": [
                {
                    "pay": "savings",
                    "start": "2029-01-01",
                    "end": "2029-12-01",
                    "amount": 10000
                }
            ],
            "customname": "Wedding",
            "id": "Wedding"
        },
        {
            "name": "",
            "inflate": true,
            "type": "other",
            "periods": [
                {
                    "pay": "net",
                    "start": "2024-01-01",
                    "end": null,
                    "amount": 1000
                }
            ],
            "customname": "Home Improvements",
            "id": "Home Improvements"
        },
        {
            "name": "",
            "inflate": true,
            "type": "other",
            "periods": [
                {
                    "pay": "savings",
                    "start": "2069-01-01",
                    "end": "2069-12-01",
                    "amount": 40000
                }
            ],
            "customname": "Kids Deposits",
            "id": "Kids Deposits"
        },
        {
            "name": "dental",
            "inflate": true,
            "type": "dental",
            "periods": [
                {
                    "pay": "net",
                    "start": "2024-01-01",
                    "end": null,
                    "amount": 400
                }
            ],
            "id": "dental"
        },
        {
            "name": "",
            "inflate": true,
            "type": "other",
            "periods": [
                {
                    "pay": "savings",
                    "start": "2051-01-01",
                    "end": "2051-12-01",
                    "amount": 10000
                }
            ],
            "customname": "Kids Cars",
            "id": "Kids Cars"
        }
    ],
    "personal": {
        "dob": {
            "you": "2004-09-01",
            "who": null,
            "partner": "2006-09-08"
        }
    },
    "savings": [
        {
            "name": "Savings",
            "type": "general",
            "id": "savings",
            "periods": [
                {
                    "start": "2024-01-01",
                    "end": "2032-12-31",
                    "amount": 0,
                    "percent": 0.195,
                    "interest": 0.04,
                    "monthly": null
                },
                {
                    "start": "2033-01-01",
                    "end": "2038-12-31",
                    "amount": 0,
                    "monthly": null,
                    "interest": 0.04,
                    "percent": 0.042
                },
                {
                    "start": "2039-01-01",
                    "end": "2055-12-31",
                    "amount": 0,
                    "monthly": null,
                    "interest": 0.04,
                    "percent": 0.05
                },
                {
                    "start": "2056-01-01",
                    "end": null,
                    "amount": 0,
                    "monthly": null,
                    "interest": 0.04,
                    "percent": 0.1
                }
            ],
            "deposits": [
                {
                    "start": 2071,
                    "end": 2071,
                    "from": "net",
                    "amount": 155000
                },
                {
                    "start": 2069,
                    "end": 2069,
                    "amount": 200000,
                    "from": "net"
                }
            ]
        },
        {
            "name": "LISA",
            "periods": [
                {
                    "start": "2024-01-01",
                    "end": "2030-12-31",
                    "amount": 0,
                    "monthly": 150,
                    "interest": 0.05,
                    "percent": null
                },
                {
                    "start": "2031-01-01",
                    "end": null,
                    "amount": 21000,
                    "monthly": 0,
                    "interest": 0,
                    "percent": null
                }
            ],
            "deposits": [
                {
                    "start": null,
                    "end": null,
                    "from": null,
                    "amount": 0
                }
            ],
            "type": "isa",
            "id": "LISA"
        },
        {
            "name": "Retirement ISA",
            "periods": [
                {
                    "start": "2056-01-01",
                    "end": "2068-12-31",
                    "amount": 0,
                    "monthly": null,
                    "interest": 0.06,
                    "percent": 0.04
                },
                {
                    "start": "2069-01-01",
                    "end": null,
                    "amount": 0,
                    "monthly": 0,
                    "interest": 0.06,
                    "percent": null
                }
            ],
            "deposits": [
                {
                    "start": null,
                    "end": null,
                    "from": null,
                    "amount": 0
                }
            ],
            "type": "isa",
            "id": "Retirement ISA"
        }
    ],
    "loans": [
        {
            "name": "Mortgage",
            "type": "mortgage",
            "periods": [
                {
                    "addition": 0,
                    "start": "2031-01-01",
                    "duration": 300,
                    "apr": 0.040999999999999995,
                    "startAmount": 200000,
                    "end": "2043-12-31",
                    "monthly": null
                },
                {
                    "start": "2044-01-01",
                    "end": null,
                    "duration": 144,
                    "addition": 0,
                    "apr": 0.040999999999999995,
                    "monthly": null,
                    "startAmount": 140000
                }
            ],
            "id": "Mortgage"
        }
    ],
    "credit": []
}